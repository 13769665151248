@import url('https://fonts.googleapis.com/css2?family=Inter&family=Merriweather&family=Noto+Sans:wght@400;600&family=Roboto&family=Roboto+Mono&family=Roboto+Slab&display=swap');

html {
	
	height:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*-----Footer----------*/
/* .footer { */
  /* flex-shrink: 2; */
  /* text-align: center;
  background-color: black;
  color: white;
  margin-top: 100px;
  margin-bottom: 0; */
  /* position:fixed; */
  /* bottom:0; */
  /* display:block; */
  /* height:30px;  
  width:100%; */
/* } */