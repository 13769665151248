#navbarScroll .links {

  font-size: 21px;
  font-family: 'Times New Roman', Times, serif;
  margin-right: 20px;

}

#mainNavbar{

height: 80px;
margin-left: 5%;
max-width: 90%;


}

#mainNavbar a{

  color: #FFFFFF;
  text-decoration: none;
}

#mainNavbar a:hover{

  color: #F9F3EE;

}

#mainNavbar a:active{

  color: #6f7bd9;

}

#navbarScroll h5:hover{

color: #3498db;
text-decoration: none;

}

#navbarScroll h5:active{

  color: #6f7bd9;

}

@media(max-width:991x){
 
}

/*-----Home page----*/

#radiobutton{
  width: 100%;
min-height: 80vh;
  margin-top: 30px;
  margin-bottom: 50px;

}

#radiobutton h1{
 /* margin: 0 0 10px 0; */
 margin-top: 20px;
 font-size: 48px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

#radiobutton .brand-name{
  color: #3498db;
  margin-right: 10px;
  margin-left: 10px;
}

#radiobutton .brand-name:hover{
  color: rgb(69, 62, 167);
}

#radiobutton h2{
 
  color: rgb(22, 20, 20);
  font-size: 25px;
  margin-top: 20px;
  letter-spacing: 1px;
}

#radiobutton h3{
 text-align: center;
  color: rgb(22, 20, 20);
  font-size: 35px;
  font-weight: 600;
  margin-top: 10px;
}

#radiobutton .headerbutton{
font-weight: 100;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  /* margin : 20px ,10px; */
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  padding: 10px ,15px;
  border-radius: 40px;
  transition: 0.5s;
  border: 2px solid;  
 text-decoration: none;

}

/* #radiobutton.btn-ge-started:hover{

  background-color: #3498db;
  color: white;
} */

.radiobutton-img{
  text-align: center;
  margin-left: 70px;
}

/*-----------------Contact Us ---------*/

#contactbox{
  width: 100%;
  height: 220vh;
  margin-top: 50px;
}

#contactbox h1{
 /* margin: 0 0 10px 0; */
 margin-top: 20px;
 font-size: 48px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

#contactbox .brand-name{
  color: #3498db;
  margin-right: 10px;
  margin-left: 10px;
}

#contactbox .brand-name:hover{
  color: rgb(69, 62, 167);
}

#contactbox h2{
 
  color: rgb(22, 20, 20);
  font-size: 25px;
  margin-top: 20px;
  letter-spacing: 1px;
}

#contactbox h3{
 text-align: center;
  color: rgb(22, 20, 20);
  font-size: 35px;
  font-weight: 600;
  margin-top: 10px;
}
 
  #contactbox p {
   margin-top: 20px;
   font-size: 20px;
   letter-spacing: 0.7px;
 }
 
 #contactbox .headerbutton{
 font-weight: 100;
   font-size: 16px;
   letter-spacing: 1px;
   display: inline-block;
   justify-content: center;
   align-items: center;
   margin-left: 45%;
   margin-bottom: 2%;
   margin-top: 5%;  
   text-align: center;
   padding: 10px;
   border-radius: 40px;
   transition: 0.5s;
   border: 2px solid;
  text-decoration: none;
 
 }
 #contactbox .placeholdars{
align-items: center;
justify-content: center;
/* text-align: center; */
margin-left: 15%;
 }

 #contactbox .form-label{

  margin-left: 15%;
  margin-top: 10px;
 }

    #contactbox .submitbtn {
      margin-left: 45%;
      margin-top: 10px;
      margin-bottom: 50px;
    }

 #contactbox  .form-check{
  margin-left: 30%;
  margin-top: 10px;
 }
 
 .contactbox-img{
  text-align: center;
  margin-left: 70px;
}
 /* #contactbox.btn-ge-started:hover{
 
   background-color: #3498db;
   color: white;
 } */
 
 /* .contactus img{
 height: 50px;
   
 
 } */

/*------------About Page----------------*/

#aboutpage{
  width: 100%;
  height: 150vh;
  margin-top: 50px;

}

#aboutpage h1{
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 48px;
   font-weight: 700;
   text-transform: uppercase;
   color: black;
 }
 
 #aboutpage .brand-name{
   color: #3498db;
 }
 
 /* #radiobutton.brand-name:hover{
   color: #2980b9;
 } */
 
 #aboutpage h2{
   color: rgb(22, 20, 20);
   font-size: 25px;
   margin-top: 50px;
   letter-spacing: 1px;
 }
 
  #aboutpage p {
   margin-top: 50px;
   letter-spacing: 0.7px;
 }
 #aboutpage h3{
   color: rgb(22, 20, 20);
   font-size: 35px;
   text-align: center ;
   font-weight: 600;
   margin-top: 30px;
 }
 
 #aboutpage .headerbutton{
 font-weight: 100;
   font-size: 16px;
   letter-spacing: 1px;
   display: inline-block;
   justify-content: center;
   align-items: center;
   margin-left: 45%;
   margin-bottom: 2%;
   margin-top: 5%;  
   text-align: center;
   padding: 10px;
   border-radius: 40px;
   transition: 0.5s;
   border: 2px solid;
  text-decoration: none;
 
 }
 
 /* #radiobutton.btn-ge-started:hover{
 
   background-color: #3498db;
   color: white;
 } */
 
 .contactus{
 height: 50px;
   padding-top: 50px;
 
 }
 
 #aboutpage.animated {
 
   animation: up-down 2s ease-in-out infinite alternate-reverse both;
 }
 



/*------Dropdowns Page--------*/

#dropdownbox{

  width: 100%;
  height: 170vh;
  margin-top: 5px;
  
}

#dropdownbox h1{
 margin: 100px 0 40px 0;
 text-align: center;
 font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
}

#dropdownbox .brand-name{
  color: #3498db;
}

/* #radiobutton.brand-name:hover{
  color: #2980b9;
} */

#dropdownbox p {
  color: rgb(22, 20, 20);
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  letter-spacing: 1px;
}

#dropdownbox h3{
  color: rgb(22, 20, 20);
  font-size: 35px;
  font-weight: 600;
  margin-top: 30px;
}

#dropdownbox h5{
  color: rgb(22, 20, 20);
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}

#dropdownbox .headerbutton{
font-weight: 100;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  border-radius: 40px;
  transition: 0.5s;
  border: 2px solid;
 text-decoration: none;

}

.dropdownboxbutton{
  margin-left: 150px;
  margin-top: 100px;
}

.backbutton{
  margin-top: 150px;
  margin-left: 150px; 
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;  

} 

.dropdownbox-img{
  text-align: right;
  padding-top: 50px;

}



/*----------------Table Component------------------*/

.Sqlfilter{
 font-size: 13px ;
  justify-content: center;
  border: 0.3px solid black;
  margin-top: 10px;
  margin-bottom: 50px;
  /* white-space: nowrap; */
  /* font-weight: 300; */
  width: 100%;
  /* text-align: center; */
}

.tablecolumn{
  margin-left: 50px;
}

.table-container{

  width: 100%;
}

#tableheads{
margin-top: 130px;
justify-content: center;
text-align: center;

}
#tableheads h1{
  
  color: #3498db;;
}

.tablehead{
  font-weight: bold;
  color: #ffff;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #6f7bd9  ;
  
   }
  
   .tablebody tr:nth-child(even) {
    background-color: #f8f7fe  ;
    
  }
  .tablebody tr:nth-child(odd) {
    background-color: #ffffff;
    
  }

  .headerbutton{
    margin-top: 10px;
    margin-bottom:20px;
  }

  /*----------------------Footer-------------------*/

  #footer {
    padding: 7%, 12%;
    margin-top: auto;
    overflow: hidden;
  }
  #footer a {
    text-decoration: none;
    color: #ffff;
  }
  #footer a:hover{
    color: #3498db;
  }
  #footer p {
    margin-top: 10px;
    margin-left: 25px;
  }
  .Social-icons {
    margin: 20px, 10px;
  }

  #footer .Copyright {
    text-align: center;
  }

  #footer .sections {
    margin-top: 20px;
    margin-left: 11%;
  }
  #footer .sections2{
    margin-top: 20px;
    margin-right: 10px;
  }
  #footer .sections3{
  margin-top: 20px;

  }

  #footer h4 {

    margin-left: 20px;
  }

  #footer .Social-icons{
    margin: 10px 10px;
  }

  #footer ul{
  list-style: none;
  }
